<template>
  <div id="section1">
    <div id="sec1-text">
      <h3>The Manage</h3>
      <h2>Elevating Your Supply Chain with</h2>
      <div class="slideshow vertical">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="slide"
          :style="{ transform: `translateY(-${currentSlide * 100}%)` }"
        >
          {{ slide }}
        </div>
      </div>
      <p>
        Tailoring blockchain Solutions for your unique supply chain challenges,
        we will collaborate with you, ensuring your supply chain is meticulously
        crafted to meet your specific needs.
      </p>
      <div id="sec1-btn" @click="toggleCalendly">Get in Touch</div>
      <div
        class="calendly-inline-widget"
        data-url="
https://calendly.com/themanage/get-in-touch?hide_event_type_details=1&hide_gdpr_banner=1&background_color=04042d&text_color=f8f9fb&primary_color=2613b7"
        v-show="flag === true"
        style="
          min-width: 60vw;
          height: 80vh;
          position: absolute;
          top: 12%;
          left: 20%;
          z-index: 100;
          /* border: 2px solid red; */
          /* background-color: #04042e; */
          /* overflow: hidden; */
        "
      ></div>
      <div
        v-show="flag === true"
        style="
          position: absolute;
          top: 15%;
          right: 5%;
          /* border: 2px solid white; */
          display: flex;
          justify-content: center;
          cursor: pointer;
          z-index: 100;
        "
        @click="closeCalendly"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="15"
          viewBox="0 0 19 15"
          fill="none"
        >
          <path
            d="M3.521 13.8677L14.521 1.86768"
            stroke="white"
            stroke-width="1.9"
            stroke-linecap="round"
          />
          <path
            d="M3.4585 1.84424L15.4585 13.8442"
            stroke="white"
            stroke-width="1.9"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
    <div id="sec1-video">
      <video autoplay muted loop id="video-bg">
        <source :src="videoUrl" type="video/mp4" id="video-source" />
      </video>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      slides: [
        "Customized Solutions",
        "Blockchain Excellence",
        "Sustainable Innovation",
        "30% Cost Reductions",
      ],
      currentSlide: 0,
      videoUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/video/cube-bg.mp4",
    };
  },
  mounted() {
    setInterval(this.nextSlide, 3000); // Change slide every 3 seconds
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
  },
  setup() {
    const index = ref(0);
    const Index = computed(() => {
      return index.value;
    });
    const router = useRouter();
    function rotate() {
      if (index.value == 2) {
        index.value = 0;
      } else index.value = index.value + 1;
      setTimeout(() => {
        rotate();
      }, 3000);
    }
    function redirect() {
      router.push({ name: "contactPage" });
    }
    onMounted(() => {
      setTimeout(() => {
        rotate();
      }, 3000);
    });

    let flag = ref(false);
    function toggleCalendly() {
      flag.value = true; // Toggle the flag when the button is clicked
    }
    function closeCalendly() {
      flag.value = false; // Toggle the flag when the button is clicked
    }

    return {
      Index,
      redirect,
      flag,
      toggleCalendly,
      closeCalendly,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anek+Latin:wght@700&display=swap");
@media only screen and (max-width: 480px) {
  #section1 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5.5vh;
    /* padding-left: 15vw; */
    overflow: hidden;
  }
  #sec1-text {
    /* width: 100vw; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
  }
  #sec1-text h2 {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 31.32px */
    letter-spacing: 0.8px;
    margin-bottom: 0;
    margin-top: 0vh;
  }
  #sec1-text h3 {
    text-align: center;
    font-family: Anek Latin;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 156.6%; /* 40.716px */
    letter-spacing: 1.04px;
    margin-top: 0px;
    background-image: linear-gradient(80deg, #4749e7 44.38%, #fff 100.59%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0.75vh;
  }
  ul li {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 156.6%; /* 31.562px */
    letter-spacing: 0.806px;
    margin-bottom: 0;
  }
  .box {
    height: 18px;
    overflow: hidden;
    position: relative;
    margin-top: 1.5vh;
  }
  ul li {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-bottom: 0;
  }
  ul {
    float: right;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in-out;
    -webkit-animation: scrollUp 3s ease-in-out infinite normal;
    animation: scrollUp 3s ease-in-out infinite normal;
  }

  @-webkit-keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-80%);
      transform: translateY(-80%);
    }
  }

  @keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-80%);
      transform: translateY(-80%);
    }
  }
  #sec1-text p {
    color: #fdfdfd;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 178.571% */
    text-transform: capitalize;
    width: 85vw;
    margin-top: 2.5vh;
  }
  #sec1-btn {
    width: 50vw;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.172px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 13.27px; /* 132.697% */
    letter-spacing: 0.9px;
    margin-top: 2vh;
    cursor: pointer;
  }
  #sec1-video {
    width: 40vw;
    height: 80vh;
    overflow: hidden;
    margin-top: -15vh;
  }
  #video-bg {
    width: 60vw;
    height: 80vh;
    z-index: -1;
    overflow: hidden;
  }
  #video-source {
    width: 60vw;
    height: 80vh;
  }
  .slideshow {
    width: 100vw; /* Set width as per your design */
    height: 50px; /* Set height as per your design */
    overflow: hidden;
    position: relative;
    /* border: 1px solid #ccc; */
    color: white;
    font-weight: 800;
    font-size: 33px;
    font-family: Inter;
    letter-spacing: 1px;
    margin-top: 1rem;
  }
  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    transition: transform 0.5s ease-in-out;
  }
  /* Adjust for vertical slide */
  .slideshow.vertical .slide {
    flex-direction: column;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  * {
    overflow-x: hidden;
  }
  #section1 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3.5vh;
    padding-left: 20vw;
    overflow: hidden;
  }
  #sec1-text {
    width: 55vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #sec1-text h2 {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 35.618px;
    font-style: normal;
    font-weight: bolder;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-bottom: 0;
    margin-top: 1vh;
  }
  #sec1-text h3 {
    text-align: center;
    font-family: Anek Latin;
    font-size: 43px;
    font-style: normal;
    font-weight: 800;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-top: 0px;
    background-image: linear-gradient(80deg, #4749e7 44.38%, #fff 100.59%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0;
  }
  ul li {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 35.618px;
    font-style: normal;
    font-weight: bolder;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-bottom: 0;
    margin-top: 0.75vh;
  }
  .box {
    height: 45px;
    overflow: hidden;
    position: relative;
    margin-top: 1.5vh;
  }
  ul li {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-bottom: 0;
  }
  ul {
    float: right;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in-out;
    -webkit-animation: scrollUp 3s ease-in-out infinite normal;
    animation: scrollUp 3s ease-in-out infinite normal;
  }

  @-webkit-keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-80%);
      transform: translateY(-80%);
    }
  }

  @keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-80%);
      transform: translateY(-80%);
    }
  }
  #sec1-text p {
    color: #fdfdfd;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 34.603px; /* 150% */
    text-transform: capitalize;
    /* width: 55vw; */
    margin-top: 2vh;
  }
  #sec1-btn {
    width: 198.747px;
    height: 60.25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.172px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16.432px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.648px; /* 150% */
    letter-spacing: 1.479px;
    margin-top: 2vh;
    cursor: pointer;
  }
  #sec1-video {
    width: 40vw;
    height: 20vh;
    overflow: hidden;
  }
  #video-bg {
    width: 40vw;
    height: 20vh;
    z-index: -1;
    overflow: hidden;
  }
  #video-source {
    width: 40vw;
    height: 60%;
  }
  .slideshow {
    width: 100vw; /* Set width as per your design */
    height: 50px; /* Set height as per your design */
    overflow: hidden;
    position: relative;
    /* border: 1px solid #ccc; */
    color: white;
    font-weight: 800;
    font-size: 33px;
    font-family: Inter;
    letter-spacing: 1px;
    margin-top: 1rem;
  }
  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    transition: transform 0.5s ease-in-out;
  }
  /* Adjust for vertical slide */
  .slideshow.vertical .slide {
    flex-direction: column;
  }
}
@media only screen and (min-width: 961px) {
  * {
    overflow-x: hidden;
  }
  #section1 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 9.5vh;
    padding-left: 20vw;
    overflow: hidden;
  }
  #sec1-text {
    width: 55vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #sec1-text h2 {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-bottom: 0;
    margin-top: 1.75vh;
  }
  #sec1-text h3 {
    font-family: "Anek Latin", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: bolder;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-top: 0px;
    background-image: linear-gradient(80deg, #4749e7 44.38%, #fff 100.59%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0;
  }
  .box {
    height: 40px;
    overflow: hidden;
    position: relative;
    margin-top: 3.5vh;
  }
  ul li {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 156.6%; /* 87.098px */
    letter-spacing: 2.225px;
    margin-bottom: 0;
  }
  ul {
    float: right;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease-in-out;
    -webkit-animation: scrollUp 3s ease-in-out infinite normal;
    animation: scrollUp 3s ease-in-out infinite normal;
  }

  @-webkit-keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-80%);
      transform: translateY(-80%);
    }
  }

  @keyframes scrollUp {
    from {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    to {
      -webkit-transform: translateY(-80%);
      transform: translateY(-80%);
    }
  }

  #sec1-text p {
    color: #fdfdfd;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 34.603px; /* 150% */
    text-transform: capitalize;
    /* width: 53vw; */
    margin-top: 3.5vh;
  }
  #sec1-btn {
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9.172px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.648px; /* 150% */
    letter-spacing: 1.479px;
    margin-top: 3vh;
    cursor: pointer;
  }
  #sec1-video {
    width: 40vw;
    height: 60vh;
    overflow: hidden;
  }
  #video-bg {
    width: 40vw;
    height: 60vh;
    z-index: -1;
    overflow: hidden;
  }
  #video-source {
    width: 40vw;
    height: 60vh;
  }
  .slideshow {
    width: 40vw; /* Set width as per your design */
    height: 50px; /* Set height as per your design */
    overflow: hidden;
    position: relative;
    /* border: 1px solid #ccc; */
    color: white;
    font-weight: 800;
    font-size: 33px;
    font-family: Inter;
    letter-spacing: 1px;
    margin-top: 1rem;
  }
  .slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    transition: transform 0.5s ease-in-out;
  }
  /* Adjust for vertical slide */
  .slideshow.vertical .slide {
    flex-direction: column;
  }

  .lmtWIHO_gkbTeeyuvoJC::-webkit-scrollbar {
    display: none;
  }
}
</style>
