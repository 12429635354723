<template>
  <div>
    <h2 id="sec5-heading">Features</h2>
    <div id="section5">
      <div class="sec5-1" id="first">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl" alt="Managed" />
            <h3>Tailored Solutions</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Enjoy personalized blockchain solutions designed to meet the
              specific needs and challenges of your supply chain.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl2" alt="Managed" />
            <h3>Enhanced Security Measures</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Ensure the utmost protection of your data with advanced security
              features, safeguarding sensitive information by using blockchain
              for supply chain.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl3" alt="Managed" />
            <h3>Unified Data Management</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Experience the convenience of centralized data management,
              providing a cohesive view of your supply chain activities for
              streamlined decision-making.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl4" alt="Managed" />
            <h3>Real-time Monitoring</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Stay informed with instant updates on your supply chain processes,
              enabling proactive decision-making and quick responses to the
              changing scenarios.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl5" alt="Managed" />
            <h3>Actionable Insights</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Gain valuable insights from comprehensive analytics, empowering
              you to make data-driven decisions that optimize your supply chain
              performance.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl6" alt="Managed" style="scale: 1.5" />
            <h3>User-Friendly Interface</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Navigate effortlessly through our platform with an intuitive and
              user-friendly interface, ensuring a seamless experience for users
              at every level.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl7" alt="Managed" />
            <h3>Scalability</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Grow your supply chain operations with ease. Our scalable
              solutions adapt to your evolving business needs, ensuring
              sustained success.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl8" alt="Managed" />
            <h3>Communication</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Foster teamwork and communication within your supply chain
              ecosystem with collaborative features that enhance coordination
              and efficiency.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1" id="last">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl9" alt="Managed" />
            <h3>Automated Processes</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Improve operational efficiency with automated workflows, reducing
              manual interventions and enhancing the overall speed of your
              supply chain.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl10" alt="Managed" />
            <h3>Continuous Innovation</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Stay ahead of the curve with a platform committed to continuous
              innovation, ensuring your supply chain solution evolves in tandem
              with industry advancements and your personal needs.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-1.svg",
      imageUrl2:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-2.svg",
      imageUrl3:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-3.svg",
      imageUrl4:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-4.svg",
      imageUrl5:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-5.svg",
      imageUrl6:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-6.svg",
      imageUrl7:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-7.svg",
      imageUrl8:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-8.svg",
      imageUrl9:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-9.svg",
      imageUrl10:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/Mask-group-10.svg",
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #sec5-heading {
    color: #fff;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 25.056px */
    letter-spacing: 0.64px;
    /* margin-left: 3vw; */
    padding: 5.5vh 20px 5px;
  }
  #section5 {
    /* width: 0%; */
    margin-top: 4vh;
    margin: 0 20px;
    /* padding: 0 5px; */
    border-radius: 17px;
    border: 0px solid rgba(216, 216, 216, 0);
    background: radial-gradient(
      352.91% 209.9% at -41.14% -59.31%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* backdrop-filter: blur(21px); */
    /* padding: 1px 0; */
  }

  #first {
    padding-top: 20px;
  }

  #last {
    padding-bottom: 20px;
  }
  .sec5-1 {
    width: 100%;
    /* margin-top: 6.5vh;
    margin-bottom: 6.5vh; */
    padding: 0px 0 0 0;
  }
  .sec51-left {
    /* width: 86vw;
    padding-left: 4vw;
    padding-right: 4vw; */
    padding: 0 20px;
  }
  .sec51-right {
    /* width: 86vw;
    padding-left: 4vw;
    padding-right: 4vw; */
    padding: 0 20px;
  }
  .sec5-1-row-1 {
    width: 100%;
    display: flex;
    margin: 0;
  }

  .sec5-1-row-1 img {
    width: 40px;
    height: 40px;
    float: left;
  }
  .sec5-1-row-1 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 2vw;
    margin-top: 3vh;
  }
  .sec5-1-row-2 {
    margin-top: 0;
  }
  .sec5-1-row-2 h4 {
    color: #fff;

    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-left: 0vw;
    margin-top: 0;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #sec5-heading {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 25.056px */
    letter-spacing: 0.64px;
    margin-left: 5vw;
    margin-bottom: 0;
    margin-top: 2vh;
  }
  #section5 {
    width: 90vw;
    margin-top: 2.75vh;
    margin-left: 5vw;
    margin-right: 5vw;
    border-radius: 17px;
    border: 0px solid rgba(216, 216, 216, 0);
    background: radial-gradient(
      352.91% 209.9% at -41.14% -59.31%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(21px);
    padding-top: 3.5vh;
    padding-bottom: 3.5vh;
    overflow: hidden;
  }
  .sec5-1 {
    width: 90vw;
    margin-bottom: 0;
  }
  .sec51-left {
    width: 85vw;
    padding-left: 3vw;
    padding-right: 2vw;
    margin-top: 3.25vh;
    margin-bottom: 3.25vh;
  }
  .sec51-right {
    width: 85vw;
    padding-left: 3vw;
    padding-right: 2vw;
    margin-top: 3.25vh;
    margin-bottom: 3.25vh;
  }
  .sec5-1-row-1 {
    width: 86vw;
    display: flex;
  }
  .sec5-1-row-1 {
    margin-bottom: 0vh;
  }
  .sec5-1-row-1 img {
    width: 54px;
    height: 54px;
    float: left;
  }
  .sec5-1-row-1 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1.75vw;
    margin-top: 2.5vh;
  }
  .sec5-1-row-2 {
    margin-top: -0.95vh;
  }
  .sec5-1-row-2 h4 {
    color: #fff;

    font-family: Quicksand;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    margin-left: 0.5vw;
    margin-top: 0;
  }
}
@media only screen and (min-width: 961px) {
  #sec5-heading {
    color: #fff;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 50.112px */
    letter-spacing: 1.28px;
    margin-left: 9vw;
    margin-bottom: 0;
    margin-top: 8rem;
  }
  #section5 {
    width: 82vw;
    margin-top: 4vh;
    margin-left: 9vw;
    margin-right: 9vw;
    border-radius: 37px;
    border: 0px solid rgba(216, 216, 216, 0);
    background: radial-gradient(
      352.91% 209.9% at -41.14% -59.31%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    backdrop-filter: blur(21px);
    padding-top: 6vh;
    padding-bottom: 6vh;
    overflow: hidden;
  }
  .sec5-1 {
    display: flex;
    flex-direction: row;
    width: 80vw;
    gap: 13vw;
    padding-left: 1vw;
    padding-right: 1vw;
    height: 22vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .sec51-left {
    width: 30vw;
    margin-left: 5vw;
  }
  .sec51-right {
    width: 30vw;
  }
  .sec5-1-row-1 {
    width: 30vw;
    display: flex;
  }
  .sec5-1-row-1 {
    margin-bottom: 1vh;
  }
  .sec5-1-row-1 img {
    width: 51px;
    height: 51px;
    float: left;
  }
  .sec5-1-row-1 h3 {
    color: #fff;
    font-family: Sans-Serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    float: left;
    margin-left: 15px;
  }
  .sec5-1-row-2 {
    margin-top: 0;
  }
  .sec5-1-row-2 h4 {
    margin-top: 0;
    color: white;
    font-family: Quicksand;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0vw;
  }
}
</style>
