<template>
  <div id="navigation">
    <!-- <img
      :src="require('../../assets/general/icon.png')"
      alt="Managed"
      id="nav-logo"
      @click="setPage(1, 'landingPage')"
    /> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101"
      height="50"
      viewBox="0 0 104 76"
      fill="none"
      id="nav-logo"
      @click="setPage(1, 'landingPage')"
    >
      <g filter="url(#filter0_d_237_162)">
        <path
          d="M56.0875 61.6796C55.2769 64.1135 52.1031 64.7014 50.4745 62.7194L13.6057 17.8523C11.8927 15.7677 13.3082 12.6233 16.0046 12.5235L31.9627 11.9334C32.9925 11.8953 33.9797 12.3463 34.6251 13.1496L60.1111 44.8706C60.8052 45.7346 61.013 46.892 60.6628 47.9434L56.0875 61.6796Z"
          fill="url(#paint0_linear_237_162)"
        />
      </g>
      <g filter="url(#filter1_d_237_162)">
        <path
          d="M91.5805 60.5401C90.7696 62.9736 87.5961 63.5612 85.9676 61.5795L49.9879 17.7954C48.275 15.7109 49.6902 12.5667 52.3863 12.4666L67.8687 11.8921C68.8986 11.8539 69.886 12.3048 70.5316 13.1083L95.468 44.1449C96.1623 45.0089 96.37 46.1665 96.0196 47.2181L91.5805 60.5401Z"
          fill="url(#paint1_linear_237_162)"
        />
      </g>
      <g filter="url(#filter2_d_237_162)">
        <path
          d="M19.2358 46.8456C19.9548 46.0488 21.2147 46.0796 21.894 46.9106L33.5485 61.1687C34.4869 62.3167 33.6646 64.0387 32.1819 64.0306L7.78158 63.8962C6.26764 63.8879 5.47575 62.0933 6.49005 60.9694L19.2358 46.8456Z"
          fill="url(#paint2_linear_237_162)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_237_162"
          x="7.35878"
          y="11.9312"
          width="58.9714"
          height="62.9802"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.50011" />
          <feGaussianBlur stdDeviation="2.75005" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.78 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_237_162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_237_162"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_237_162"
          x="43.7411"
          y="11.8896"
          width="57.946"
          height="61.8816"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.50011" />
          <feGaussianBlur stdDeviation="2.75005" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.78 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_237_162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_237_162"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_237_162"
          x="0.535049"
          y="46.2671"
          width="38.9128"
          height="28.7639"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.50011" />
          <feGaussianBlur stdDeviation="2.75005" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.78 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_237_162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_237_162"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_237_162"
          x1="64.5126"
          y1="59.9782"
          x2="15.1587"
          y2="-9.7248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#220FB4" />
          <stop offset="1" stop-color="#4C4BF6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_237_162"
          x1="99.8032"
          y1="58.9807"
          x2="51.4302"
          y2="-9.33576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#220FB4" />
          <stop offset="1" stop-color="#4C4BF6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_237_162"
          x1="13.3993"
          y1="24.1252"
          x2="15.3272"
          y2="63.3856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#423DE7" />
          <stop offset="1" stop-color="#2513B9" />
        </linearGradient>
      </defs>
    </svg>
    <div id="navigations">
      <div
        :class="Active == '1' ? 'navigation-active' : 'navigation-passive'"
        @click="setPage(1, 'landingPage')"
        style="margin-right: 9px"
      >
        Home
      </div>
      <div
        :class="Active == '2' ? 'navigation-active' : 'navigation-passive'"
        @click="setPage(2, 'aboutPage')"
      >
        About Us
      </div>
      <div
        :class="Active == '3' ? 'navigation-active' : 'navigation-passive'"
        @click="setPage(3, 'productPage')"
      >
        Products
      </div>
    </div>
    <div id="contact" @click="toggleCalendly">Connect</div>
    <div
      class="calendly-inline-widget"
      data-url="
https://calendly.com/themanage/get-in-touch?hide_event_type_details=1&hide_gdpr_banner=1&background_color=04042d&text_color=f8f9fb&primary_color=2613b7"
      v-show="flag"
      style="
        min-width: 60vw;
        height: 700px;
        position: absolute;
        top: 12%;
        left: 20%;
        z-index: 100;
        /* border: 2px solid red; */
        /* background-color: #04042e; */
        /* overflow: hidden; */
      "
    ></div>
    <div
      v-show="flag"
      style="
        position: absolute;
        top: 15%;
        right: 25%;
        /* border: 2px solid white; */
        display: flex;
        justify-content: center;
        cursor: pointer;
        z-index: 100;
      "
      @click="closeCalendly"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="15"
        viewBox="0 0 19 15"
        fill="none"
      >
        <path
          d="M3.521 13.8677L14.521 1.86768"
          stroke="white"
          stroke-width="1.9"
          stroke-linecap="round"
        />
        <path
          d="M3.4585 1.84424L15.4585 13.8442"
          stroke="white"
          stroke-width="1.9"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const Active = computed(() => {
      return store.getters["getActivePage"];
    });
    const setPage = (index, page) => {
      store.commit("setActivePage", index);
      router.push({ name: page });
    };

    let flag = ref(false);
    function toggleCalendly() {
      flag.value = true; // Toggle the flag when the button is clicked
    }
    function closeCalendly() {
      flag.value = false; // Toggle the flag when the button is clicked
    }

    return {
      Active,
      setPage,
      flag,
      toggleCalendly,
      closeCalendly,
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #navigation {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 960px) {
  #navigation {
    width: 100vw;
    margin-top: 2vh;

    display: flex;
    flex-direction: row;
    gap: 12vw;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  #nav-logo {
    width: 60.002px;
    height: 45.066px;
    flex-shrink: 0;
  }
  #navigations {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4vw;
  }
  .navigation-active {
    width: 110px;
    height: 40px;
    border-radius: 18px;
    background: rgba(34, 15, 179, 1);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
  .navigation-passive {
    width: 110px;
    height: 40px;
    border-radius: 48px;
    background: transparent;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
  #contact {
    width: 110px;
    height: 40px;
    border-radius: 48px;
    background: linear-gradient(180deg, #220fb3 0%, #413ce5 100%);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 961px) {
  #navigation {
    width: 100vw;
    margin-top: 1.5rem;
    display: flex;
    /* flex-direction: row; */
    /* gap: 20vw; */
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
  }
  #nav-logo {
    width: 80.002px;
    height: 55.066px;
    flex-shrink: 0;
    cursor: pointer;
  }
  #navigations {
    width: 30vw;
    display: flex;
    justify-content: space-around;
  }
  .navigation-active {
    padding: 12px 24px;
    border-radius: 18px;
    background: #220fb3;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
  .navigation-passive {
    padding: 12px 24px;
    border-radius: 18px;
    background: transparent;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
  #contact {
    padding: 12px 24px;
    border-radius: 22px;
    background: linear-gradient(180deg, #220fb3 0%, #413ce5 100%);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
  }
}
</style>
