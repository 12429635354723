<template>
  <div id="footer">
    <div id="footer-divider"></div>
    <div id="footer-content">
      <div id="footer-title">
        <img :src="imageUrl" alt="Manage" />
        <h2 style="padding-top: 3px">The Manage</h2>
      </div>
      <h3 style="font-size: 18px">
        WeWork Enam Sambhav, G Block Road, Mumbai 400051, India
      </h3>
      <div id="startup">
        <span>Recognized By</span>
        <img src="@/assets/startup-india.svg" alt="StartUp India" />
      </div>
      <div id="footer-links">
        <h4 @click="redirectPage('landingPage')">Home</h4>
        <h4 @click="redirectPage('aboutPage')">About us</h4>
        <h4 @click="redirectPage('productPage')">Product</h4>
        <h4 @click="scrollToSection('section1')">Connect</h4>
      </div>
      <div id="social-links">
        <img
          @click="socialRedirect('https://www.linkedin.com/company/themanage/')"
          :src="imageUrl2"
          alt="Linkedin"
        />
        <img
          @click="socialRedirect('https://www.instagram.com/themanage.co/')"
          :src="imageUrl3"
          alt="Instagram"
        />
        <img
          @click="socialRedirect('https://twitter.com/KuvakaTech/')"
          :src="imageUrl4"
          alt="X"
        />
        <img
          @click="socialRedirect('https://t.me/KuvakaTech/')"
          :src="imageUrl5"
          alt="Telegram"
        />
        <img
          @click="socialRedirect('https://discord.com/invite/xKWmADRXjX')"
          :src="imageUrl6"
          alt="Discord"
        />
      </div>
      <h5 style="font-size: 17px; padding-bottom: 7px">+91 9977104518</h5>
      <h5 @click="openEmail" style="cursor: pointer; font-size: 17px">
        themanage@kuvaka.io
      </h5>
      <h5
        style="margin-top: 5vh; font-size: 17px; cursor: pointer"
        @click="socialRedirect('https://kuvaka.io/')"
      >
        Powered by Kuvaka Tech
      </h5>
      <h5
        style="
          margin-top: 6.5vh;
          font-size: 17px;
          line-height: 2;
          display: flex;
          flex-direction: column-reverse;
        "
      >
        <span
          >© {{ new Date().getFullYear() }} Kuvaka Tech (OPC) Private
          Limited</span
        >
        <span> All Rights Reserved</span>
      </h5>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  data() {
    return {
      imageUrl: "https://d266jsegxhahfs.cloudfront.net/Manage/logo.svg",
      imageUrl2: "https://d266jsegxhahfs.cloudfront.net/Manage/linkedin.svg",
      imageUrl3: "https://d266jsegxhahfs.cloudfront.net/Manage/instagram.svg",
      imageUrl4: "https://d266jsegxhahfs.cloudfront.net/Manage/twitter.svg",
      imageUrl5: "https://d266jsegxhahfs.cloudfront.net/Manage/telegram.svg",
      imageUrl6: "https://d266jsegxhahfs.cloudfront.net/Manage/discord.svg",
      imageUrl7: "/src/assets/startup-india.svg",
    };
  },

  setup() {
    const router = useRouter();
    const redirectPage = (val) => {
      router.push({ name: val });
    };
    const socialRedirect = (val) => {
      window.open(val, "_blank");
    };
    const openEmail = () => {
      window.location.href = "mailto:themanage@kuvaka.io";
    };
    return {
      redirectPage,
      socialRedirect,
      openEmail,
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@media only screen and (max-width: 480px) {
  #footer {
    width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
    overflow: hidden;
    margin-top: 8vh;
    margin-bottom: 2vh;
  }
  #footer-divider {
    width: 94vw;
    height: 2.39px;
    background: rgba(252, 252, 252, 0.33);
    margin-bottom: 3vh;
  }
  #footer-content {
    width: 94vw;
    margin-bottom: 3vh;
    padding-top: 1.5vh;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    justify-content: center;
  }
  #footer-title {
    width: 94vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #footer-title img {
    width: 72.375px;
    height: 49.49px;
  }
  #footer-title h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 32.427px; /* 142.233% */
    margin-left: 7px;
  }
  #footer-content h3 {
    width: 100%;
    /* border: 2px solid white; */
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 0;
  }
  #footer-links {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 0;
  }
  #footer-links h4 {
    color: var(--blue-blue-70, #3a86ff);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.857px;
  }
  #social-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 5.75vw;
    margin-bottom: 1.95vh;
  }
  #social-links img {
    width: 35px;
    height: 35px;
  }
  #footer-content h5 {
    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 70%;
    margin-top: 0;
    margin-bottom: 0.45vh;
  }
  #startup {
    display: flex;
    background: #fff;
    width: auto;
    margin: auto;
    justify-content: center;
    align-items: start;
    padding: 0.5rem 2rem 0.1rem;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    border-radius: 20px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #footer {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    overflow: hidden;
    margin-top: -7vh;
    margin-bottom: 2vh;
  }
  #footer-divider {
    width: 90vw;
    height: 2.39px;
    background: rgba(252, 252, 252, 0.33);
    margin-bottom: 2vh;
  }
  #footer-content {
    width: 90vw;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    gap: -1vw;
    justify-content: center;
  }
  #footer-title {
    display: flex;
    justify-content: center;
  }
  #footer-title img {
    width: 92.375px;
    height: 59.49px;
  }
  #footer-title h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.558px; /* 142.233% */
    margin-left: 7px;
  }
  #footer-content h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 31.5px */
    margin-top: 0;
  }
  #footer-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 5vw;
  }
  #footer-links h4 {
    color: var(--blue-blue-70, #3a86ff);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.429px; /* 137.5% */
    cursor: pointer;
  }
  #social-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 5vw;
    margin-top: 1vh;
    margin-bottom: 4vh;
  }
  #social-links img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }
  #footer-content h5 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 70%; /* 31.5px */
    margin-top: 0;
    margin-bottom: 1vh;
  }
  #startup {
    display: flex;
    background: #fff;
    width: 35%;
    margin: auto;
    justify-content: center;
    align-items: start;
    padding: 0.5rem 2rem 0.1rem;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    border-radius: 20px;
  }
}
@media only screen and (min-width: 961px) {
  #footer {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    overflow: hidden;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
  #footer-divider {
    width: 60vw;
    height: 2.39px;
    background: rgba(252, 252, 252, 0.33);
    margin-bottom: 3vh;
    margin-left: 15vw;
    margin-right: 15vw;
  }
  #footer-content {
    width: 60vw;
    margin-bottom: 3vh;
    margin-left: 15vw;
    margin-right: 15vw;
    padding-top: 1.5vh;
  }
  #footer-title {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #footer-title img {
    width: 72.375px;
    height: 49.49px;
  }
  #footer-title h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 21.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.558px; /* 142.233% */
    margin-left: 7px;
    margin-top: 0;
  }
  #footer-content h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 31.5px */
    width: 30vw;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 1.5vh;
  }
  #footer-links {
    width: 60vw;
    display: flex;
    justify-content: center;
    gap: 4vw;
  }
  #footer-links h4 {
    color: var(--blue-blue-70, #3a86ff);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 19.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.429px; /* 137.5% */
    cursor: pointer;
  }
  #social-links {
    width: 60vw;
    display: flex;
    justify-content: center;
    gap: 4vw;
    margin-top: 1vh;
    margin-bottom: 5.6vh;
  }
  #social-links img {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
  #footer-content h5 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 31.5px */
    margin-top: 0;
    margin-bottom: 0.75vh;
  }
  #startup {
    display: flex;
    background: #fff;
    width: 37.5%;
    margin: auto;
    justify-content: center;
    align-items: start;
    padding: 0.5rem 2rem 0.1rem;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    border-radius: 20px;
  }
}
</style>
