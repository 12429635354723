<template>
  <div id="section-two">
    <div id="badges">
      <div id="badge-row-1">
        <badge-comp
          h2="Microsoft Excel"
          h3="Requisitions_Equipment_v4"
          img="0"
        ></badge-comp>
        <badge-comp
          h2="Slack"
          h3="Hey, Can you approve this?"
          img="1"
        ></badge-comp>
      </div>
      <div id="badge-row-2">
        <badge-comp
          h2="Calendar"
          h3="Budget Report for Q2"
          img="2"
        ></badge-comp>
        <badge-comp
          h2="Whatsapp"
          h3="Hello, Wanted a quotation?"
          img="3"
        ></badge-comp>
      </div>
    </div>
    <div>
      <img :src="imageUrl" alt="Manage" id="sec2-image" />
    </div>
  </div>
</template>

<script>
import BadgeComp from "../general/badge-comp.vue";
export default {
  data() {
    return {
      imageUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/animated/product-dashboard.svg",
    };
  },
  components: { BadgeComp },
};
</script>

<style scoped>
@media only screen and (max-width: 480px) {
  #section-two {
    /* margin-top: -2vh; */
    margin: -2vh 20px 0;
  }
  #badge-row-1 {
    display: none;
  }
  #badge-row-2 {
    display: none;
  }
  #sec2-image {
    /* border: 2px solid red; */
    width: 100%;
    height: 43vh;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("../../assets/landing/animated/product-dashboard.svg"); */
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 23px;
  }
  #sec2-image img {
    width: 88vw;
    height: 90%;
    box-shadow: 10.481px 2.795px 2.795px 0px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #section-two {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: -3vh;
  }
  #badge-row-1 {
    display: none;
  }
  #badge-row-2 {
    display: none;
  }
  #sec2-image {
    width: 90vw;
    height: 60vh;
    margin-top: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("../../assets/landing/animated/product-dashboard.svg"); */
    border-radius: 17px;
  }
  #sec2-image img {
    width: 86vw;
    height: 93%;
    box-shadow: 10.481px 2.795px 2.795px 0px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
  }
}
@media only screen and (min-width: 961px) {
  #section-two {
    width: 82vw;
    margin-left: 9vw;
    margin-right: 9vw;
    margin-top: -20vh;
  }
  #badge-row-1 {
    display: flex;
    width: 78vw;
    padding-left: 2vw;
    padding-right: 2vw;
    gap: 48vw;
    margin-bottom: 0;
  }
  #badge-row-2 {
    display: flex;
    width: 70vw;
    padding-left: 6vw;
    padding-right: 6vw;
    gap: 40vw;
    margin-top: 7.6vh;
  }
  #sec2-image {
    width: 82vw;
    height: 129vh;
    margin-top: 10vh;
    margin-bottom: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: url("../../assets/landing/animated/product-dashboard.svg"); */
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 23px;
  }
  #sec2-image img {
    width: 76vw;
    height: 92vh;
    box-shadow: 10.481px 2.795px 2.795px 0px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
  }
}
</style>
