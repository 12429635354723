<template>
  <div id="section-three">
    <div class="sec3-1" id="admin">
      <div class="sec3-image">
        <img :src="require('../../assets/feature/admin.svg')" alt="Manage" />
      </div>
      <div class="sec3-content">
        <div class="sec3-col1">
          <h2>Admin</h2>
          <h3>
            Centralized management, procurement planning, and secure
            authorization for efficient supply chain control.
          </h3>
        </div>
        <div class="sec3-col2">
          <div class="sec3-col-content">
            <h2>Centralized Control</h2>
            <h3>
              Act as the single-point control hub for Trident's entire business
              operations.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Real-Time Management</h2>
            <h3>
              Receive real-time information from all stakeholders involved in
              the supply chain.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Strategic Decision-Making</h2>
            <h3>
              Utilize detailed information for data-driven and strategic
              decision-making.
            </h3>
          </div>
        </div>
        <div class="sec3-col3">
          <div class="sec3-col-content">
            <h2>Procurement Oversight</h2>
            <h3>
              Oversee the procurement of packaging material based on BlockPro
              insights.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Efficient Order Fulfillment</h2>
            <h3>
              Efficiently manage and oversee the lifecycle of orders for
              seamless fulfillment.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Comprehensive Documentation</h2>
            <h3>
              Generate and download all necessary documents directly from
              BlockPro.
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="sec3-1" id="manager">
      <div class="sec3-image">
        <img :src="require('../../assets/feature/manager.svg')" alt="Manage" />
      </div>
      <div class="sec3-content">
        <div class="sec3-col1">
          <h2>Manager</h2>
          <h3>
            Integrate barcodes, monitor transit and payments, and manage
            documentation for a seamless product flow.
          </h3>
        </div>
        <div class="sec3-col2">
          <div class="sec3-col-content">
            <h2>Barcode Integration</h2>
            <h3>
              Ensure traceability with barcode integration for streamlined
              operations.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Shipment Tracking</h2>
            <h3>
              Provide end-to-end visibility of shipments, integrating with
              logistics partners for real-time tracking.
            </h3>
          </div>
        </div>
        <div class="sec3-col3">
          <div class="sec3-col-content">
            <h2>Temperature Control</h2>
            <h3>
              Monitor and control temperature for products in cold storage,
              ensuring quality and freshness.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Documentation Management</h2>
            <h3>
              Efficiently manage documentation for cargo loading, final quality
              checks, and shipping updates.
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="sec3-1" id="supervisor">
      <div class="sec3-image">
        <img
          :src="require('../../assets/feature/supervisor.svg')"
          alt="Manage"
        />
      </div>
      <div class="sec3-content">
        <div class="sec3-col1">
          <h2>Supervisor</h2>
          <h3>
            Oversee field visits, maintain accountability, and access real-time
            information for smooth operations.
          </h3>
        </div>
        <div class="sec3-col2">
          <div class="sec3-col-content">
            <h2>Real-Time Information</h2>
            <h3>
              Receive up-to-the-minute updates from field inspectors and
              vendors/farmers for informed decision-making.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Field Visit Management</h2>
            <h3>
              Efficiently manage and oversee field visits, ensuring compliance
              with company policies.
            </h3>
          </div>
        </div>
        <div class="sec3-col3">
          <div class="sec3-col-content">
            <h2>Accountability</h2>
            <h3>
              Every action taken is stored and shared with the Admin for
              transparency and accountability.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Transporter Information</h2>
            <h3>
              Access critical transporter details to facilitate smooth transit
              operations.
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="sec3-1" id="floorstaff">
      <div class="sec3-image">
        <img
          :src="require('../../assets/feature/floor-staff.svg')"
          alt="Manage"
        />
      </div>
      <div class="sec3-content">
        <div class="sec3-col1">
          <h2>Floor Staff</h2>
          <h3>
            Efficiently manage sites, conduct surveys, and ensure real-time data
            entry for a streamlined supply chain.
          </h3>
        </div>
        <div class="sec3-col2">
          <div class="sec3-col-content">
            <h2>Sign-Up and Authentication</h2>
            <h3>
              Experience a seamless onboarding process with verified and
              approved access by the Central Admin.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Data Entry for Sites</h2>
            <h3>
              Enter and update vendor details, farm specifics, farmer
              information, soil details, and more.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Documentation with Multimedia</h2>
            <h3>
              Enrich data entries by adding photos and PDFs, ensuring
              comprehensive and detailed records.
            </h3>
          </div>
        </div>
        <div class="sec3-col3">
          <div class="sec3-col-content">
            <h2>Site Management</h2>
            <h3>
              Effortlessly manage multiple sites, categorizing crucial
              information for a comprehensive overview.
            </h3>
          </div>
          <div class="sec3-col-content">
            <h2>Timestamps and Milestones</h2>
            <h3>
              Mark timestamps for visits and add milestones during the crop care
              phase.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/feature/admin.svg",
      imageUrl2:
        "https://d266jsegxhahfs.cloudfront.net/Manage/feature/manager.svg",
      imageUrl3:
        "https://d266jsegxhahfs.cloudfront.net/Manage/feature/supervisor.svg",
      imageUrl4:
        "https://d266jsegxhahfs.cloudfront.net/Manage/feature/floor-staff.svg",
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");
@media only screen and (max-width: 480px) {
  #section-three {
    width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }
  .sec3-1 {
    width: 94vw;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  .sec3-image {
    width: 94vw;
    height: 15vh;
    margin-bottom: 4vh;
  }
  .sec3-image img {
    width: 94vw;
    height: 15vh;
    margin-top: 4vh;
  }
  .sec3-content {
    width: 94vw;
    margin-top: 5.9vh;
  }
  .sec3-col1 {
    width: 94vw;
  }
  .sec3-col1 h2 {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 156.6%; /* 31.32px */
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
  .sec3-col1 h3 {
    color: #fff;

    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.298px;
    margin-top: 1vh;
  }
  .sec3-col2,
  .sec3-col3 {
    width: 94vw;
    margin-top: 2.75vh;
    margin-bottom: 2.75vh;
  }
  .sec3-col-content {
    width: 94vw;
  }
  .sec3-col-content h2 {
    color: #fff;
    font-family: Inter;
    font-size: 20.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 37.25px; /* 150% */
    margin-bottom: 0;
  }
  .sec3-col-content h3 {
    color: #fff;

    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.298px;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #section-three {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 3vh;
  }
  .sec3-1 {
    width: 90vw;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
  .sec3-image {
    width: 90vw;
    height: 15vh;
    margin-bottom: 4vh;
  }
  .sec3-image img {
    width: 90vw;
    height: 15vh;
    margin-top: 4vh;
  }
  .sec3-content {
    width: 90vw;
    margin-top: 5.9vh;
  }
  .sec3-col1 {
    width: 90vw;
  }
  .sec3-col1 h2 {
    color: #fff;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 156.6%; /* 31.32px */
    letter-spacing: 0.8px;
    margin-bottom: 0;
  }
  .sec3-col1 h3 {
    color: #fff;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.298px;
    margin-top: 1vh;
  }
  .sec3-col2,
  .sec3-col3 {
    width: 90vw;
    margin-top: 2.75vh;
    margin-bottom: 2.75vh;
  }
  .sec3-col-content {
    width: 90vw;
  }
  .sec3-col-content h2 {
    color: #fff;
    font-family: Inter;
    font-size: 24.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 37.25px; /* 150% */
    margin-bottom: 0;
  }
  .sec3-col-content h3 {
    color: #fff;

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.298px;
    margin-top: 1vh;
  }
}
@media only screen and (min-width: 961px) {
  #section-three {
    width: 100vw;
    margin-top: 8vh;
  }
  .sec3-1 {
    width: 82vw;
    margin-left: 9vw;
    margin-right: 9vw;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  .sec3-image {
    width: 82vw;
    height: 20vh;
    margin-bottom: 2vh;
  }
  .sec3-image img {
    width: 82vw;
    height: 20vh;
    margin-top: 4vh;
  }
  .sec3-content {
    width: 78vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    display: grid;
    grid-template-columns: repeat(3, 24vw);
    gap: 3vw;
    margin-top: 8vh;
  }
  .sec3-col1 {
    width: 24vw;
  }
  .sec3-col1 h2 {
    color: #fff;
    font-family: Inter;
    font-size: 54.59px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3vh;
  }
  .sec3-col1 h3 {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px; /* 150% */
    margin-top: 0;
  }
  .sec3-col2,
  .sec3-col3 {
    width: 24vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
  .sec3-col-content {
    width: 24vw;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  .sec3-col-content h2 {
    color: #fff;
    font-family: Inter;
    font-size: 25.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 37.25px; /* 150% */
    margin-bottom: 0;
  }
  .sec3-col-content h3 {
    color: #fff;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 25s.5px; /* 150% */
    margin-top: 1.75vh;
  }
}
</style>
