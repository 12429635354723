<template>
  <div id="section1">
    <div id="sec1-video">
      <video autoplay muted loop id="video-bg">
        <source :src="videoUrl" type="video/mp4" id="video-source" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/video/cube-bg.mp4",
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 480px) {
  * {
    overflow-x: hidden;
  }
  #section1 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    padding-left: 15vw;
    overflow: hidden;
  }
  #sec1-text {
    width: 85vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #sec1-text h2 {
    color: #fff;
    text-align: center;
    font-family: Anek Latin;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 31.32px */
    letter-spacing: 0.8px;
    margin-bottom: 0;
    margin-top: 0vh;
  }
  #sec1-text p {
    color: #fdfdfd;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 178.571% */
    text-transform: capitalize;
    width: 65vw;
    margin-top: 2.5vh;
  }
  #sec1-btn {
    width: 50vw;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.172px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 13.27px; /* 132.697% */
    letter-spacing: 0.9px;
    margin-top: 3vh;
    cursor: pointer;
  }
  #sec1-video {
    width: 40vw;
    margin-left: 40vw;
    overflow: hidden;
    margin-top: -2vh;
  }
  #video-bg {
    width: 60vw;
    height: 80%;
    overflow: hidden;
  }
  #video-source {
    width: 60vw;
    height: 80%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  * {
    overflow-x: hidden;
  }
  #section1 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
    padding-left: 20vw;
    overflow: hidden;
  }
  #sec1-video {
    width: 40vw;
    height: 20vh;
    overflow: hidden;
    margin-left: 45vw;
  }
  #video-bg {
    width: 40vw;
    height: 20vh;
    z-index: -1;
    overflow: hidden;
  }
  #video-source {
    width: 40vw;
    height: 60%;
  }
}
@media only screen and (min-width: 961px) {
  * {
    overflow-x: hidden;
  }
  #section1 {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0vh;
    padding-left: 46.75vw;
    overflow: hidden;
    margin-top: -1vh;
    margin-bottom: 0;
  }
  #sec1-video {
    width: 40vw;
    height: 60vh;
    overflow: hidden;
  }
  #video-bg {
    width: 40vw;
    height: 60vh;
    z-index: -1;
    overflow: hidden;
  }
  #video-source {
    width: 40vw;
    height: 60vh;
  }
}
</style>
