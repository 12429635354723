<template>
  <div id="animation-badge">
    <img :src="imageUrl1" :alt="h2" v-if="img == 0" />
    <img
      :src="imageUrl2"
      :alt="h2"
      style="padding: 5px 0 0 3px; width: 40px; height: 40px"
      v-if="img == 1"
    />
    <img
      :src="imageUrl3"
      :alt="h2"
      style="padding: 5px 0 0 3px; width: 40px; height: 40px"
      v-if="img == 2"
    />
    <img
      :src="imageUrl4"
      :alt="h2"
      style="padding: 5px 0 0 3px; width: 43px; height: 43px"
      v-if="img == 3"
    />
    <div id="badge-text">
      <h2>{{ h2 }}</h2>
      <h3>{{ h3 }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/badges/excel.svg",
      imageUrl2:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/badges/slack.svg",
      imageUrl3:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/badges/calender.svg",
      imageUrl4:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/badges/whatsapp.svg",
    };
  },
  props: ["img", "h2", "h3"],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

@media only screen and (max-width: 480px) {
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
}
@media only screen and (min-width: 961px) {
  #animation-badge {
    width: 319px;
    height: 58px;
    border-radius: 16px;
    background: #fffeff;
    display: flex;
    padding-left: 10px;
    align-items: center;
  }
  #animation-badge img {
    width: 45px;
    height: 45px;
    margin-right: 5px;
  }
  #badge-text {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding-top: 6px;
    height: 58px;
    /* border: 2px solid red; */
  }
  #badge-text h2 {
    color: #232740;
    font-family: Roboto;
    font-size: 13.482px;
    font-family: Roboto;
    font-weight: 600;
    margin: 0;
    word-wrap: break-word;
  }
  #badge-text h3 {
    color: #6b6e81;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.723px; /* 176.595% */
    margin: 0;
    word-wrap: break-word;
  }
}
</style>
