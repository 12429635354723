<template>
  <div>
    <h2 id="sec5-heading">Features</h2>
    <div id="section5">
      <div class="sec5-1" id="first">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl" alt="Managed" />
            <h3>Automotive Compliance Optimization</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Navigate complex automotive regulations effortlessly with The
              Manage's block chain technology, our real-time monitoring ensures
              unparalleled compliance, setting the industry standard. Keep track
              of all the Invoices and transactions.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl2" alt="Managed" />
            <h3>Pharmaceutical Traceability</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Immerse your supply chain data in a cryptographic fortress. The
              Managed employs a private blockchain architecture, implementing
              robust cryptographic algorithms to fortify data security. This
              impenetrable layer ensures confidentiality, integrity, and
              resistance against unauthorized access.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl3" alt="Managed" />
            <h3>Retail Supply Chain Transparency</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Elevate trust and visibility in retail operations with The
              Manage's Blockchain for Transparent Supply Chain Ecosystems.
              Real-time monitoring brings unprecedented transparency to meet
              consumer demands.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl4" alt="Managed" />
            <h3>Manufacturing Process Optimization</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Optimize manufacturing processes with The Manage's efficient
              blockchain network. Our blockchain solutions reduce lead times and
              elevate efficiency in intricate manufacturing operations and data
              sharing.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl5" alt="Managed" />
            <h3>Agricultural Supply Chain Sustainability</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Lead the way in sustainable agriculture with The Manage's
              Sustainable Supply Chain Blockchain. Our solutions provide
              end-to-end visibility, supporting ethical sourcing practices in
              the agricultural supply chain.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl6" alt="Managed" />
            <h3>Document Management Simplified</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Centralize and secure your invoices and crucial documents
              effortlessly with The Manage. Streamline your supply chain
              operations by having access to all information on one dashboard.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl7" alt="Managed" />
            <h3>Healthcare Logistics Efficiency</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Improve healthcare logistics efficiency with The Manage's
              Real-time Logistics Tracking Solutions. Our blockchain integration
              reduces delays and enhances patient care through efficient supply
              chain operations and cold chain monitoring
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl8" alt="Managed" />
            <h3>Technology Component Verification</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Guarantee the legitimacy of technology components using The
              Manage's cutting-edge verification solutions. Our platform
              establishes a secure and unalterable ledger, providing
              comprehensive authenticity tracking for technology hardware
              components.
            </h4>
          </div>
        </div>
      </div>
      <div class="sec5-1" id="last">
        <div class="sec51-left">
          <div class="sec5-1-row-1">
            <img :src="imageUrl9" alt="Managed" />
            <h3>Fashion Industry Ethical Sourcing</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Reshape the future of fashion with The Manage's Sustainable and
              Ethical Sourcing Solutions. Our blockchain solutions promote
              supply chains that uphold ethical practices in the fashion
              industry and transparency.
            </h4>
          </div>
        </div>
        <div class="sec51-right">
          <div class="sec5-1-row-1">
            <img :src="imageUrl10" alt="Managed" />
            <h3>Food and Beverage Quality Assurance</h3>
          </div>
          <div class="sec5-1-row-2">
            <h4>
              Ensure product integrity and safety in the food and beverage
              industry with The Manage's Enhanced Supply Chain Traceability. Our
              blockchain solutions enable real-time monitoring of quality
              checkpoints for ultimate quality assurance.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group1.svg",
      imageUrl2:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group2.svg",
      imageUrl3:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group3.svg",
      imageUrl4:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group4.svg",
      imageUrl5:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group5.svg",
      imageUrl6:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group6.svg",
      imageUrl7:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group7.svg",
      imageUrl8:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group8.svg",
      imageUrl9:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group9.svg",
      imageUrl10:
        "https://d266jsegxhahfs.cloudfront.net/Manage/product/Mask-group10.svg",
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #sec5-heading {
    color: #fff;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 25.056px */
    letter-spacing: 0.64px;
    /* margin-left: 3vw; */
    padding: 5.5vh 20px 5px;
  }
  #section5 {
    /* width: 0%; */
    margin-top: 4vh;
    margin: 0 20px;
    /* padding: 0 5px; */
    border-radius: 17px;
    border: 0px solid rgba(216, 216, 216, 0);
    background: radial-gradient(
      352.91% 209.9% at -41.14% -59.31%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    /* backdrop-filter: blur(21px); */
    /* padding: 1px 0; */
  }

  #first {
    padding-top: 20px;
  }

  #last {
    padding-bottom: 20px;
  }
  .sec5-1 {
    width: 100%;
    /* margin-top: 6.5vh;
    margin-bottom: 6.5vh; */
    padding: 0px 0 0 0;
  }
  .sec51-left {
    /* width: 86vw;
    padding-left: 4vw;
    padding-right: 4vw; */
    padding: 0 20px;
  }
  .sec51-right {
    /* width: 86vw;
    padding-left: 4vw;
    padding-right: 4vw; */
    padding: 0 20px;
  }
  .sec5-1-row-1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .sec5-1-row-1 img {
    width: 40px;
    height: 40px;
    float: left;
  }
  .sec5-1-row-1 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 2vw;
    margin-top: 3vh;
  }
  .sec5-1-row-2 {
    margin-top: 0;
  }
  .sec5-1-row-2 h4 {
    color: #fff;

    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-left: 0vw;
    margin-top: 0;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #sec5-heading {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 25.056px */
    letter-spacing: 0.64px;
    margin-left: 5vw;
    margin-bottom: 0;
    margin-top: 2vh;
  }
  #section5 {
    width: 90vw;
    margin-top: 2.75vh;
    margin-left: 5vw;
    margin-right: 5vw;
    border-radius: 17px;
    border: 0px solid rgba(216, 216, 216, 0);
    background: radial-gradient(
      352.91% 209.9% at -41.14% -59.31%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(21px);
    padding-top: 3.5vh;
    padding-bottom: 3.5vh;
    overflow: hidden;
  }
  .sec5-1 {
    width: 90vw;
    margin-bottom: 0;
  }
  .sec51-left {
    width: 85vw;
    padding-left: 3vw;
    padding-right: 2vw;
    margin-top: 3.25vh;
    margin-bottom: 3.25vh;
  }
  .sec51-right {
    width: 85vw;
    padding-left: 3vw;
    padding-right: 2vw;
    margin-top: 3.25vh;
    margin-bottom: 3.25vh;
  }
  .sec5-1-row-1 {
    width: 86vw;
    display: flex;
    align-items: center;
  }
  .sec5-1-row-1 {
    margin-bottom: 0vh;
  }
  .sec5-1-row-1 img {
    width: 54px;
    height: 54px;
    float: left;
  }
  .sec5-1-row-1 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1.75vw;
    margin-top: 2.5vh;
  }
  .sec5-1-row-2 {
    margin-top: -0.95vh;
  }
  .sec5-1-row-2 h4 {
    color: #fff;

    font-family: Quicksand;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    margin-left: 0.5vw;
    margin-top: 0;
  }
}
@media only screen and (min-width: 961px) {
  #sec5-heading {
    color: #fff;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 50.112px */
    letter-spacing: 1.28px;
    margin-left: 9vw;
    margin-bottom: 0;
    margin-top: 8rem;
  }
  #section5 {
    width: 82vw;
    margin-top: 4vh;
    margin-left: 9vw;
    margin-right: 9vw;
    border-radius: 37px;
    border: 0px solid rgba(216, 216, 216, 0);
    background: radial-gradient(
      352.91% 209.9% at -41.14% -59.31%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    backdrop-filter: blur(21px);
    padding-top: 6vh;
    padding-bottom: 6vh;
    overflow: hidden;
  }
  .sec5-1 {
    display: flex;
    flex-direction: row;
    width: 80vw;
    gap: 13vw;
    padding-left: 1vw;
    padding-right: 1vw;
    height: 22vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .sec51-left {
    width: 30vw;
    margin-left: 5vw;
  }
  .sec51-right {
    width: 30vw;
  }
  .sec5-1-row-1 {
    width: 30vw;
    display: flex;
    align-items: center;
  }
  .sec5-1-row-1 {
    margin-bottom: 1vh;
    align-items: center;
  }
  .sec5-1-row-1 img {
    width: 51px;
    height: 51px;
    float: left;
  }
  .sec5-1-row-1 h3 {
    color: #fff;
    font-family: Sans-Serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    float: left;
    margin-left: 15px;
  }
  .sec5-1-row-2 {
    margin-top: 0;
  }
  .sec5-1-row-2 h4 {
    margin-top: 0;
    color: white;
    font-family: Quicksand;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0vw;
  }
}
</style>
