<template>
  <div id="container">
    <top-nav></top-nav>
    <mobile-nav></mobile-nav>
    <section-one></section-one>
    <section-two></section-two>
    <section-three></section-three>
    <footer-comp></footer-comp>
  </div>
</template>

<script>
import TopNav from "../components/general/top-nav.vue";
import MobileNav from "../components/general/mobile-nav.vue";
import SectionOne from "../components/contact/section-one.vue";
import SectionTwo from "../components/contact/section-two.vue";
import SectionThree from "../components/contact/section-three.vue";
import FooterComp from "../components/general/footer-contact.vue";
import { useStore } from "vuex";
import { onMounted } from "vue";
export default {
  components: {
    TopNav,
    MobileNav,
    SectionOne,
    SectionTwo,
    SectionThree,
    FooterComp,
  },
  setup() {
    onMounted(() => {
      const store = useStore();
      store.commit("setActivePage", 0);
    });
    return {};
  },
};
</script>
<style scoped>
@media only screen and (max-width: 480px) {
  #container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #04042e;
    padding-bottom: 4vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
  #container::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 480px) and (max-width: 960px) {
  #container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #04042e;
    padding-bottom: 4vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
  #container::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  #container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #04042e;
    padding-bottom: 4vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
  #container::-webkit-scrollbar {
    display: none;
  }
}
</style>
