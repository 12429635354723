import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LandingPage from '../views/landing-page.vue'
import ProductPage from '../views/product-page.vue'
import AboutPage from '../views/about-us.vue'
import FeaturePage from '../views/features-page.vue'
import ContactPage from '../views/contact-page.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name:'contactPage',
    path: '/contact',
    component: ContactPage
  },
  {
    name:'aboutPage',
    path: '/about',
    component: AboutPage
  },
  {
    name:'featurePage',
    path: '/features',
    component: FeaturePage
  },
  {
    name:'landingPage',
    path: '/',
    component: LandingPage
  },
  {
      name:'productPage',
      path: '/product',
      component: ProductPage
  },
  { 
    path:'/home',
    redirect:'/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
