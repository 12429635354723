<template>
  <div id="mobile-nav" style="z-index: 100">
    <!-- Image -->
    <div
      class=""
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
      "
    >
      <div>
        <img src="../../assets/logo.svg" alt="" style="height: 40px" />
      </div>
      <div>
        <Transition name="slide">
          <div @click="toggleNav">
            <div v-if="showNav">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
              >
                <path
                  d="M3.521 13.8677L14.521 1.86768"
                  stroke="white"
                  stroke-width="1.9"
                  stroke-linecap="round"
                />
                <path
                  d="M3.4585 1.84424L15.4585 13.8442"
                  stroke="white"
                  stroke-width="1.9"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div v-else>
              <img src="../../assets/hamburger.svg" alt="" />
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <Transition name="slide-fade">
      <div
        v-if="showNav"
        style="
          position: absolute;
          width: 100%;
          margin: auto;
          padding: 20px 0;
          z-index: 100;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #04042e;
        "
      >
        <h2
          @click="setPage('landingPage')"
          style="
            text-align: center;
            font-size: 14px;
            color: white;
            font-family: Inter;
            letter-spacing: 1px;
            /* width: 90%; */
          "
        >
          HOME
        </h2>
        <div id="nav-divider"></div>
        <h2
          @click="setPage('aboutPage')"
          style="
            text-align: center;
            font-size: 14px;
            color: white;
            font-family: Inter;
            letter-spacing: 1px;
            /* width: 90%; */
          "
        >
          ABOUT US
        </h2>
        <div id="nav-divider"></div>
        <h2
          @click="setPage('productPage')"
          style="
            text-align: center;
            font-size: 14px;
            color: white;
            font-family: Inter;
            letter-spacing: 1px;
            /* width: 90%; */
          "
        >
          PRODUCTS
        </h2>
        <button id="nav-connect" @click="toggleCalendly">Connect</button>
      </div>
    </Transition>
  </div>
  <div
    class="calendly-inline-widget"
    data-url="
https://calendly.com/themanage/get-in-touch?hide_event_type_details=1&hide_gdpr_banner=1&background_color=04042d&text_color=f8f9fb&primary_color=2613b7"
    v-show="flag"
    style="
      min-width: 98vw;
      height: 80vh;
      position: absolute;
      top: 19%;
      right: 5px;
      z-index: 100;
      /* border: 2px solid red; */
      /* background-color: #04042e; */
      /* overflow: hidden; */
    "
  ></div>
  <div
    v-show="flag"
    style="
      position: absolute;
      top: 14.5%;
      right: 15px;
      /* border: 2px solid white; */
      display: flex;
      justify-content: center;
      cursor: pointer;
      z-index: 100;
    "
    @click="closeCalendly"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
    >
      <path
        d="M3.521 13.8677L14.521 1.86768"
        stroke="white"
        stroke-width="1.9"
        stroke-linecap="round"
      />
      <path
        d="M3.4585 1.84424L15.4585 13.8442"
        stroke="white"
        stroke-width="1.9"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

export default {
  data() {
    return {
      showNav: false,
    };
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
  },

  setup() {
    const toggle = ref(false);
    const showNav = ref(false);
    const router = useRouter();
    const Toggle = computed(() => {
      return toggle.value;
    });
    const handleToggle = (value) => {
      toggle.value = value;
    };
    const setPage = (page) => {
      router.push({ name: page });
    };

    let flag = ref(false);
    function toggleCalendly() {
      showNav.value = false;
      flag.value = true; // Toggle the flag when the button is clicked
    }
    function closeCalendly() {
      flag.value = false; // Toggle the flag when the button is clicked
    }

    return {
      Toggle,
      handleToggle,
      setPage,
      flag,
      toggleCalendly,
      closeCalendly,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 480px) {
  #mobile-nav {
    width: 100%;
    padding-top: 3vh;
    z-index: 1;
  }
  #nav-close,
  #nav-open {
    width: 94vw;
    display: flex;
    padding-left: 3vw;
    padding-right: 3vw;
    gap: 69vw;
    transition: all 0.5s ease-in-out;
    z-index: 100;
  }
  #nav-menu {
    width: 100%;
    /* padding-left: 5vw; */
    /* padding-right: 3vw; */
    /* margin-top: 3.5vh; */
    text-align: center;
    transition: all 0.5s ease-in-out;
  }
  #nav-close img,
  #nav-open img {
    width: 54.998px;
    height: 37.611px;
  }
  #nav-close svg,
  #nav-open svg {
    margin-top: 1vh;
  }
  #nav-menu h2 {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.266px; /* 123.326% */
    letter-spacing: 0.247px;
    text-transform: uppercase;
    margin-left: 0.35vw;
    margin-bottom: 0.75vh;
  }
  #nav-divider {
    background: #2626af;
    height: 2px;
    width: 90%;
    margin-top: 1.75vh;
    margin-bottom: 1.75vh;
  }
  #nav-connect {
    width: 80vw;
    margin: 1rem 0;
    padding: 5px 0;
    height: 5.75vh;
    border-radius: 48px;
    background: linear-gradient(180deg, #220fb3 0%, #413ce5 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    /* font-weight: 600; */
    /* line-height: normal; */
    border: none;
  }
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #mobile-nav {
    display: none;
  }
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
@media only screen and (min-width: 961px) {
  #mobile-nav {
    display: none;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
</style>
