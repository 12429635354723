<template>
  <div id="footer">
    <div id="footer-divider"></div>
    <div id="footer-content">
      <div id="footer-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="75"
          height="54"
          viewBox="0 0 75 54"
          fill="none"
        >
          <g filter="url(#filter0_d_237_528)">
            <path
              d="M39.8171 44.1972C39.2362 45.9411 36.962 46.3624 35.795 44.9422L9.37621 12.7922C8.14873 11.2984 9.16304 9.0452 11.0951 8.97375L22.5301 8.55087C23.268 8.52359 23.9754 8.84673 24.4379 9.42238L42.7002 32.1525C43.1976 32.7715 43.3465 33.6009 43.0955 34.3543L39.8171 44.1972Z"
              fill="url(#paint0_linear_237_528)"
            />
          </g>
          <g filter="url(#filter1_d_237_528)">
            <path
              d="M65.2494 43.3784C64.6683 45.1221 62.3943 45.5432 61.2274 44.1232L35.4457 12.7492C34.2183 11.2555 35.2324 9.00243 37.1643 8.93074L48.2584 8.51906C48.9964 8.49168 49.7039 8.81482 50.1665 9.39055L68.035 31.6302C68.5325 32.2494 68.6814 33.0788 68.4303 33.8323L65.2494 43.3784Z"
              fill="url(#paint1_linear_237_528)"
            />
          </g>
          <g filter="url(#filter2_d_237_528)">
            <path
              d="M13.4079 33.5734C13.9231 33.0024 14.8259 33.0245 15.3126 33.62L23.6639 43.8368C24.3363 44.6594 23.747 45.8933 22.6846 45.8875L5.20023 45.7912C4.1154 45.7853 3.54795 44.4993 4.27476 43.694L13.4079 33.5734Z"
              fill="url(#paint2_linear_237_528)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_237_528"
              x="4.89965"
              y="8.54932"
              width="42.2569"
              height="45.1294"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.94117" />
              <feGaussianBlur stdDeviation="1.97058" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.78 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_237_528"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_237_528"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_237_528"
              x="30.9695"
              y="8.51758"
              width="41.522"
              height="44.3418"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.94117" />
              <feGaussianBlur stdDeviation="1.97058" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.78 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_237_528"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_237_528"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_237_528"
              x="0.00756121"
              y="33.1587"
              width="27.8833"
              height="20.6113"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.94117" />
              <feGaussianBlur stdDeviation="1.97058" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.78 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_237_528"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_237_528"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_237_528"
              x1="45.8542"
              y1="42.978"
              x2="10.489"
              y2="-6.96858"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#220FB4" />
              <stop offset="1" stop-color="#4C4BF6" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_237_528"
              x1="71.1414"
              y1="42.261"
              x2="36.4792"
              y2="-6.69202"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#220FB4" />
              <stop offset="1" stop-color="#4C4BF6" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_237_528"
              x1="9.22564"
              y1="17.2928"
              x2="10.6071"
              y2="45.4253"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#423DE7" />
              <stop offset="1" stop-color="#2513B9" />
            </linearGradient>
          </defs>
        </svg>
        <h2>The Manage</h2>
      </div>
      <h3>WeWork Enam Sambhav, G Block Road, Mumbai 400051, India</h3>
      <div id="footer-links">
        <h4 @click="redirectPage('landingPage')">Home</h4>
        <h4 @click="redirectPage('aboutPage')">About us</h4>
        <h4 @click="redirectPage('productPage')">Product</h4>
        <h4 @click="redirectPage('contactPage')">Connect</h4>
      </div>
      <div id="social-links" style="z-index: 50; position: relative">
        <img
          @click="socialRedirect('https://www.linkedin.com/company/kuvaka/')"
          :src="imageUrl2"
          alt="Linkedin"
        />
        <img
          @click="socialRedirect('https://www.instagram.com/kuvakatech/')"
          :src="imageUrl3"
          alt="Instagram"
        />
        <img
          @click="socialRedirect('https://twitter.com/KuvakaTech/')"
          :src="imageUrl4"
          alt="X"
        />
        <img
          @click="socialRedirect('https://t.me/KuvakaTech/')"
          :src="imageUrl5"
          alt="Telegram"
        />
        <img
          @click="socialRedirect('https://discord.com/invite/xKWmADRXjX')"
          :src="imageUrl6"
          alt="Discord"
        />
      </div>
      <h5>+91 9977104518</h5>
      <h5 @click="openEmail" style="cursor: pointer">themanage@kuvaka.io</h5>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      imageUrl: "https://d266jsegxhahfs.cloudfront.net/Manage/logo.svg",
      imageUrl2: "https://d266jsegxhahfs.cloudfront.net/Manage/linkedin.svg",
      imageUrl3: "https://d266jsegxhahfs.cloudfront.net/Manage/instagram.svg",
      imageUrl4: "https://d266jsegxhahfs.cloudfront.net/Manage/twitter.svg",
      imageUrl5: "https://d266jsegxhahfs.cloudfront.net/Manage/telegram.svg",
      imageUrl6: "https://d266jsegxhahfs.cloudfront.net/Manage/discord.svg",
    };
  },

  setup() {
    const router = useRouter();
    const redirectPage = (val) => {
      router.push({ name: val });
    };
    const socialRedirect = (val) => {
      window.open(val, "_blank");
    };
    const openEmail = () => {
      window.location.href = "mailto:themanage@kuvaka.io ";
    };
    return {
      redirectPage,
      socialRedirect,
      openEmail,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@media only screen and (max-width: 480px) {
  #footer {
    width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
    overflow: hidden;
    margin-top: 8vh;
    margin-bottom: 2vh;
  }
  #footer-divider {
    width: 94vw;
    height: 2.39px;
    background: rgba(252, 252, 252, 0.33);
    margin-bottom: 3vh;
  }
  #footer-content {
    width: 94vw;
    margin-bottom: 3vh;
    padding-top: 1.5vh;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    justify-content: center;
  }
  #footer-title {
    width: 94vw;
    display: flex;
    justify-content: center;
  }
  #footer-title img {
    width: 72.375px;
    height: 49.49px;
  }
  #footer-title h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32.427px; /* 142.233% */
    margin-left: 7px;
  }
  #footer-content h3 {
    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 0;
  }
  #footer-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 4.5vw;
    margin-top: 0;
  }
  #footer-links h4 {
    color: var(--blue-blue-70, #3a86ff);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24.857px;
  }
  #social-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 5.75vw;
    margin-bottom: 1.95vh;
  }
  #social-links img {
    width: 35px;
    height: 35px;
  }
  #footer-content h5 {
    color: #fff;

    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 70%;
    margin-top: 0;
    margin-bottom: 0.45vh;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #footer {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    overflow: hidden;
    margin-top: -7vh;
    margin-bottom: 2vh;
  }
  #footer-divider {
    width: 90vw;
    height: 2.39px;
    background: rgba(252, 252, 252, 0.33);
    margin-bottom: 2vh;
  }
  #footer-content {
    width: 90vw;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: column;
    gap: -1vw;
    justify-content: center;
  }
  #footer-title {
    display: flex;
    justify-content: center;
  }
  #footer-title img {
    width: 92.375px;
    height: 59.49px;
  }
  #footer-title h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.558px; /* 142.233% */
    margin-left: 7px;
  }
  #footer-content h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 31.5px */
    margin-top: 0;
  }
  #footer-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 5vw;
  }
  #footer-links h4 {
    color: var(--blue-blue-70, #3a86ff);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.429px; /* 137.5% */
    cursor: pointer;
  }
  #social-links {
    width: 90vw;
    display: flex;
    justify-content: center;
    gap: 5vw;
    margin-top: 1vh;
    margin-bottom: 4vh;
  }
  #social-links img {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }
  #footer-content h5 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 70%; /* 31.5px */
    margin-top: 0;
    margin-bottom: 1vh;
  }
}
@media only screen and (min-width: 961px) {
  #footer {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    overflow: hidden;
    margin-top: 3vh;
    margin-bottom: 2vh;
  }
  #footer-divider {
    width: 60vw;
    height: 2.39px;
    background: rgba(252, 252, 252, 0.33);
    margin-bottom: 3vh;
    margin-left: 15vw;
    margin-right: 15vw;
  }
  #footer-content {
    width: 60vw;
    margin-bottom: 3vh;
    margin-left: 15vw;
    margin-right: 15vw;
    padding-top: 1.5vh;
  }
  #footer-title {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #footer-title img {
    width: 72.375px;
    height: 49.49px;
  }
  #footer-title h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 21.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 35.558px; /* 142.233% */
    margin-left: 7px;
    margin-top: 0;
  }
  #footer-content h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 31.5px */
    width: 30vw;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 1.5vh;
  }
  #footer-links {
    width: 60vw;
    display: flex;
    justify-content: center;
    gap: 4vw;
  }
  #footer-links h4 {
    color: var(--blue-blue-70, #3a86ff);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 19.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.429px; /* 137.5% */
    cursor: pointer;
  }
  #social-links {
    width: 60vw;
    display: flex;
    justify-content: center;
    gap: 4vw;
    margin-top: 1vh;
    margin-bottom: 5.6vh;
  }
  #social-links img {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
  #footer-content h5 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 31.5px */
    margin-top: 0;
    margin-bottom: 0.75vh;
  }
}
</style>
