<template>
  <div id="section6">
    <div id="sec6-1">
      <div id="sec6-tab-4">
        <h2>30</h2>
        <h4>%</h4>
        <h3>Increase in transparency</h3>
      </div>
      <div id="sec6-tab-2">
        <h2>30</h2>
        <h4>%</h4>
        <h3 style="font-size: 18px">
          Cost reduction by eliminating inefficiencies, reducing fraud, and
          optimizing processes.
        </h3>
      </div>
      <div id="sec6-tab-3">
        <h2>50</h2>
        <h4>%</h4>
        <h3>Reduction in data discrepancies and errors</h3>
      </div>
    </div>
    <div id="sec6-2">
      <div class="sec6-content">
        <h2>Maximizing Benefits: The Manage Advantage</h2>
        <p>
          Enjoy unparalleled advantages with The Manage, including cost
          reduction, risk mitigation, one-stop documentation, enhanced
          traceability, seamless peer communication, and robust security
          measures. Our blockchain solutions are meticulously designed to
          elevate your supply chain performance, ensuring enduring success for
          your business.
        </p>
      </div>
      <div class="sec6-img">
        <img
          :src="require('../../assets/landing/images/supply-chain2.svg')"
          alt="Managed"
        />
      </div>
    </div>
    <div id="sec6-3">
      <div class="sec6-img">
        <img
          :src="require('../../assets/landing/images/supply-chain3.svg')"
          alt="Managed"
        />
      </div>
      <div class="sec6-content">
        <h2>Elevate Your Operations with Unmatched Supply Chain Control</h2>
        <p>
          The Manage outshines other supply chain solutions with its unwavering
          commitment to client-centricity, customization, and unmatched
          scalability by integrating blockchain in supply chain management.
          Distinguishing itself from conventional platforms, The Manage engages
          in collaborative partnerships with clients, providing tailored
          blockchain solutions that address unique challenges head-on. The
          Manage redefines supply chain management, offering a blend of
          innovation, reliability, and steadfast dedication to the enduring
          success of our clients.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/images/supply-chain2.svg",
      imageUrl2:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/images/supply-chain3.svg",
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #section6 {
    display: none;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #section6 {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  #section6 {
    width: 100vw;
    overflow-x: hidden;
    margin-top: 17vh;
    overflow: hidden;
  }
  #sec6-1 {
    width: 82vw;
    padding-left: 9vw;
    padding-right: 9vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2vw;
  }
  #sec6-tab-1 {
    width: 18vw;
    border-radius: 20px;
    background: linear-gradient(180deg, #2310b4 0%, #4b4af4 100%);
    height: 16vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #sec6-tab-1 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 24.25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
  }
  #sec6-tab-1 h4 {
    color: #fff;
    font-family: sans-serif;
    font-size: 31.25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-top: 1.5vh;
  }
  #sec6-tab-2 {
    width: 40vw;
    height: 16vh;
    border-radius: 20px;
    background: linear-gradient(180deg, #2310b4 0%, #4b4af4 100%);
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }
  #sec6-tab-2 h2 {
    color: #fff;
    text-align: center;
    font-family: Quicksand;
    font-size: 51.25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  #sec6-tab-2 h4 {
    color: #fff;
    font-family: Quicksand;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 60px;
  }
  #sec6-tab-2 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5vw;
  }
  #sec6-tab-3 {
    height: 16vh;
    border-radius: 20px;
    background: linear-gradient(180deg, #2310b4 0%, #4b4af4 100%);
    display: flex;
    flex-direction: row;
    gap: 0.25vw;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }
  #sec6-tab-3 h2 {
    color: #fff;
    text-align: center;
    font-family: Quicksand;
    font-size: 51.25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  #sec6-tab-3 h4 {
    color: #fff;
    font-family: Quicksand;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 60px;
  }
  #sec6-tab-3 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5vw;
  }

  #sec6-tab-4 {
    width: 15vw;
    height: 16vh;
    border-radius: 20px;
    background: linear-gradient(180deg, #2310b4 0%, #4b4af4 100%);
    display: flex;
    flex-direction: row;
    gap: 0.25vw;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }
  #sec6-tab-4 h2 {
    color: #fff;
    text-align: center;
    font-family: Quicksand;
    font-size: 51.25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  #sec6-tab-4 h4 {
    color: #fff;
    font-family: Quicksand;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 60px;
  }
  #sec6-tab-4 h3 {
    color: #fff;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 0.5vw;
  }

  #sec6-2,
  #sec6-3 {
    width: 82vw;
    padding-left: 9vw;
    padding-right: 9vw;
    display: grid;
    grid-template-columns: repeat(2, 40vw);
    gap: 2vw;
    margin-top: 18vh;
  }
  .sec6-img {
    width: 40vw;
  }
  .sec6-img img {
    width: 40vw;
    height: 55vh;
  }
  .sec6-content {
    width: 40vw;
  }
  .sec6-content h3 {
    color: #4749e7;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 156.6%; /* 50.112px */
    letter-spacing: 1.28px;
    margin-bottom: 3.5vh;
    margin-top: 0;
  }
  .sec6-content h2 {
    color: #fff;
    font-family: sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 0px */
    letter-spacing: 1.44px;
    margin-top: 0;
    margin-bottom: 4vh;
  }
  .sec6-content p {
    color: rgba(255, 255, 255, 0.796);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    margin-top: 0;
  }
}
</style>
