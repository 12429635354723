<template>
  <div>
    <swiper
      :spaceBetween="10"
      :autoplay="{
        delay: 2000,
        disableOnInteraction: false,
      }"
      :grabCursor="true"
      :centeredSlides="false"
      :loop="true"
      :slides-per-view="1.5"
      :modules="modules"
      id="mobile2"
    >
      <swiper-slide class="mobile2-badge">
        <h2>Customization Excellence</h2>
        <h3>
          Collaborating closely with our clients, we understand their unique
          supply chain challenges, tailoring and building solutions that
          precisely align with their specific needs.
        </h3>
      </swiper-slide>
      <swiper-slide class="mobile2-badge">
        <h2>Intelligent Insights</h2>
        <h3>
          Our intelligent solution harnesses data to provide actionable
          insights, addressing and resolving the exact issues faced by the
          client for a smarter and more informed supply chain.
        </h3>
      </swiper-slide>
      <swiper-slide class="mobile2-badge">
        <h2>Effortless User Experience</h2>
        <h3>
          Designed with diverse stakeholders in mind, our user-friendly
          dashboards empower clients with easy navigation and enhanced control
          over their supply chain, ensuring a seamless experience.
        </h3>
      </swiper-slide>
      <swiper-slide class="mobile2-badge">
        <h2>Scalability at its Core</h2>
        <h3>
          Adapting to evolving client needs, we offer highly scalable solutions
          that grow with our client&apos;s business, ensuring long-term success
          in an ever-changing business landscape.
        </h3>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
// import required modules
import { Autoplay } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  #mobile2 {
    /* width: 100%; */
    /* margin-left: 3vw;
    margin-right: 3vw; */
    /* overflow: hidden; */
    display: flex;
    /* gap: 3.5vw; */
    margin-top: 3.5vh;
    /* overflow-x: auto; */
  }
  #mobile2::-webkit-scrollbar {
    display: none;
  }
  .mobile2-badge {
    width: 500px;
    height: 9.5rem;
    border-radius: 10px;
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
    padding: 15px 20px;
  }
  .mobile2-badge h2 {
    /* width: 100%; */
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.944px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile2-badge h3 {
    /* width: 100%; */
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 13.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.963px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #mobile2 {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  #mobile2 {
    display: none;
  }
}
</style>
