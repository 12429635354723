<template>
  <div id="section-three">
    <h2>Stakeholder Dashboard Interface</h2>
    <h3>
      The Manage solved visibility and tracebility problem of the FMCG Industry
      by integrating the following roles.
    </h3>
    <div id="sec3-badges">
      <div class="sec3-badge">
        <span>Admin</span>
        <div class="layer">
          <h4 class="description">
            Centralized management, procurement planning, and secure
            authorization for efficient supply chain control.
          </h4>
        </div>
      </div>
      <div class="sec3-badge">
        <span>Manager</span>
        <div class="layer">
          <h4 class="description">
            Integrate barcodes, monitor transit and payments, and manage
            documentation for a seamless product flow.
          </h4>
        </div>
      </div>
      <div class="sec3-badge">
        <span>Supervisor</span>
        <div class="layer">
          <h4 class="description">
            Oversee field visits, maintain accountability, and access real-time
            information for smooth operations.
          </h4>
        </div>
      </div>
      <div class="sec3-badge">
        <span>Floor Staff</span>
        <div class="layer">
          <h4 class="description">
            Efficiently manage sites, conduct surveys, and ensure real-time data
            entry for a streamlined supply chain.
          </h4>
        </div>
      </div>
    </div>
    <div class="sec3-button" @click="redirect">Explore More</div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const redirect = () => {
      router.push({ name: "featurePage" });
    };
    return {
      redirect,
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@media only screen and (max-width: 480px) {
  #section-three {
    width: 94vw;
    margin-top: 6vh;
    margin-left: 3vw;
    margin-right: 3vw;
    border-radius: 22px;
    border-bottom: none;
    border-top: 5px solid #220fb3;
    border-left: 2px solid
      linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
    border-right: 2px solid
      linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
    padding-top: 1.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #section-three h2 {
    color: #fff;

    text-align: center;
    font-family: sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 156.6%; /* 37.584px */
    letter-spacing: 0.96px;
  }
  #section-three h3 {
    color: #fdfdfd;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 178.571% */
    text-transform: capitalize;
  }
  #sec3-badges {
    width: 94vw;
    display: grid;
    margin-top: 16vh;
    grid-template-columns: repeat(2, 44vw);
    grid-gap: 4vw;
    margin-top: 4.5vh;
  }
  .sec3-badge {
    width: 44vw;
    height: 12vh;
    border-radius: 10px;
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 17.793px; /* 150% */
  }
  .sec3-button {
    width: 50vw;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10.172px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.56494px 1.56494px 7.82468px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 13.27px; /* 132.697% */
    letter-spacing: 0.9px;
    margin-top: 7vh;
    margin-bottom: 7vh;
    cursor: pointer;
  }

  .layer {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 960px) {
  #section-three {
    width: 90vw;
    margin-top: 6vh;
    margin-left: 5vw;
    margin-right: 5vw;
    border-radius: 42px;
    border-bottom: none;
    border-top: 5px solid #220fb3;
    border-left: 2px solid
      linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
    border-right: 2px solid
      linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
    padding-top: 2vh;
  }
  #section-three h2 {
    color: #fff;
    font-family: sans-serif;
    font-size: 30.406px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; /* 0px */
    letter-spacing: 1.896px;
    text-align: center;
  }
  #section-three h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 22.815px;
    font-style: normal;
    font-weight: 400;
    line-height: 41.722px; /* 150% */
    margin-top: 3vh;
  }
  #sec3-badges {
    width: 90vw;
    display: grid;
    margin-top: 4vh;
    grid-template-columns: repeat(4, 21vw);
    gap: 2vw;
  }
  .sec3-badge {
    width: 21vw;
    height: 7vh;
    border-radius: 10px;
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23.815px;
    font-style: normal;
    font-weight: 600;
    line-height: 41.722px; /* 150% */
  }
  .sec3-button {
    width: 30vw;
    height: 65px;
    border-radius: 10.747px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.653px 1.653px 8.267px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.041px; /* 124.006% */
    letter-spacing: 1.89px;
    margin-left: 30vw;
    margin-right: 30vw;
    margin-top: 4vh;
    cursor: pointer;
  }
}

@media only screen and (min-width: 961px) {
  #section-three {
    width: 82vw;
    margin-top: 16vh;
    margin-left: 9vw;
    margin-right: 9vw;
    border-radius: 42px;
    border-bottom: none;
    border-top: 5px solid #220fb3;
    border-left: 2px solid
      linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
    border-right: 2px solid
      linear-gradient(to bottom, rgba(34, 15, 179, 1), rgba(77, 76, 247, 0));
    padding-top: 5vh;
  }
  #section-three h2 {
    color: #fff;
    font-family: sans-serif;
    font-size: 47.406px;
    font-style: normal;
    font-weight: 700;
    line-height: 0%; /* 0px */
    letter-spacing: 1.896px;
    text-align: center;
  }
  #section-three h3 {
    color: #fff;
    width: 50vw;
    margin-left: 16vw;
    text-align: center;
    font-family: Inter;
    font-size: 22.815px;
    font-style: normal;
    font-weight: 400;
    line-height: 41.722px; /* 150% */
    margin-top: 8vh;
  }
  #sec3-badges {
    width: 78vw;
    padding-left: 2vw;
    padding-right: 2vw;
    display: grid;
    margin-top: 16vh;
    grid-template-columns: repeat(4, 18vw);
    gap: 2vw;
  }
  .sec3-badge {
    /* width: 18vw; */
    padding: 3rem 2rem;
    border-radius: 10px;
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23.815px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 41.722px; */
    flex-basis: 25%;
    position: relative;
    /* margin-bottom: 30px;
    border-radius: 20px; */
    overflow: hidden;
  }

  .sec3-badge span {
    width: 100%;
    height: 100%;
    display: flex;
    /* border: 2px solid yellow; */
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin: 0;
  }
  .layer {
    background-color: transparent;
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .layer:hover {
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
  }
  .layer h4 {
    margin: auto;
    width: 18vw;
    /* height: auto; */
    text-align: center;
    font-size: 14px;
    line-height: 2;
    color: whitesmoke;
    position: absolute;
    bottom: -100%;
    /* top: 100%; */
    /* left: 5%; */
    /* border: 2px solid orangered; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.5s;
    opacity: 0;
    /* margin: 0; */
    text-transform: capitalize;
  }
  .layer:hover h4 {
    bottom: 11%;
    opacity: 1;
  }
  .sec3-button {
    border-radius: 10.747px;
    background: linear-gradient(180deg, #2513b7 0%, #4a48f3 122.73%);
    box-shadow: 1.653px 1.653px 8.267px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.041px; /* 124.006% */
    letter-spacing: 1.89px;
    padding: 15px 0;
    margin: 0 33vw;
    /* margin-left: 33vw;
    margin-right: 33vw; */
    margin-top: 14vh;
    cursor: pointer;
  }
}
</style>
