<template>
  <div class="section">
    <div class="container">
      <swiper
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="false"
        :slidesPerView="slidesPerView"
        :coverflowEffect="{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        :pagination="false"
        :navigation="false"
        :modules="modules"
        class="swiper-container"
      >
        <swiper-slide class="sec2-badge" id="first-slide">
          <h2>Scalability at its Core</h2>
          <h3>
            Adapting to evolving client needs, we offer highly scalable
            solutions that grow with our client’s business, ensuring long-term
            success in an ever-changing business landscape.
          </h3>
        </swiper-slide>
        <swiper-slide class="sec2-badge" id="second-slide">
          <h2>Customization Excellence</h2>
          <h3>
            Collaborating closely with our clients, we understand their unique
            supply chain challenges, tailoring and building solutions that
            precisely align with their specific needs.
          </h3>
        </swiper-slide>
        <swiper-slide class="sec2-badge" id="third-slide">
          <h2>Intelligent Insights</h2>
          <h3>
            Our intelligent solution harnesses data to provide actionable
            insights, addressing and resolving the exact issues faced by the
            client for a smarter and more informed supply chain.
          </h3>
        </swiper-slide>
        <swiper-slide class="sec2-badge" id="forth-slide">
          <h2>Effortless User Experience</h2>
          <h3>
            Designed with diverse stakeholders in mind, our user-friendly
            dashboards empower clients with easy navigation and enhanced control
            over their supply chain, ensuring a seamless experience.
          </h3>
        </swiper-slide>
        <swiper-slide class="sec2-badge" id="fifth-slide">
          <h2>Scalability at its Core</h2>
          <h3>
            Adapting to evolving client needs, we offer highly scalable
            solutions that grow with our client’s business, ensuring long-term
            success in an ever-changing business landscape.
          </h3>
        </swiper-slide>
        <swiper-slide class="sec2-badge" id="sixth-slide">
          <h2>Customization Excellence</h2>
          <h3>
            Collaborating closely with our clients, we understand their unique
            supply chain challenges, tailoring and building solutions that
            precisely align with their specific needs.
          </h3>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./style.css";

// import required modules
import {
  EffectCoverflow,
  Autoplay,
  Pagination,
  Navigation,
} from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slidesPerView: 1, // Default to 1 slide
      modules: [EffectCoverflow, Autoplay, Pagination, Navigation],
    };
  },
  created() {
    window.addEventListener("resize", this.updateSlidesPerView);
    this.updateSlidesPerView(); // Initial call
  },
  unmounted() {
    window.removeEventListener("resize", this.updateSlidesPerView);
  },
  methods: {
    updateSlidesPerView() {
      if (window.innerWidth >= 961) {
        this.slidesPerView = 3; // Desktop
      } else if (window.innerWidth >= 481 && window.innerWidth <= 960) {
        this.slidesPerView = 2; // Tablets
      } else {
        this.slidesPerView = 1; // Mobile
      }
    },
  },
  // setup() {
  //   return {
  //     modules: [EffectCoverflow, Autoplay, Pagination, Navigation],
  //   };
  // },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@media only screen and (max-width: 480px) {
  .section {
    display: none;
  }
  .container {
    /* width: 15%; */
    height: 100%;
    /* display: flex;
    justify-content: center; */
    /* border: 2px solid white; */
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  .section {
    display: none;
  }
  .container {
    width: 90%;
    height: 100%;
    /* display: flex;
    justify-content: center; */
    /* border: 2px solid white; */
  }
  .sec2-badge {
    /* width: 100%; */
    /* border: 2px solid red; */
    /* height: 14.5rem; */
    /* scale: 1.3; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0px;
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
    /* padding-left: 1vw;
    padding-right: 1vw; */
  }
  .sec2-badge h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23.5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sec2-badge h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    width: 20vw;
  }
}
@media only screen and (min-width: 961px) {
  #section2 {
    width: 82vw;
    margin-left: 9vw;
    margin-right: 9vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 24vw);
    gap: 3.5vw;
    margin-top: 14vh;
    overflow-x: auto;
  }
  #section2::-webkit-scrollbar {
    display: none;
  }

  .container {
    width: 85%;
    height: 100%;
    /* display: flex;
    justify-content: center; */
    /* border: 2px solid white; */
  }
  .sec2-badge {
    /* width: 100%; */
    /* border: 2px solid red; */
    height: 14.5rem;
    /* scale: 1.3; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    background: linear-gradient(180deg, #220fb3 0%, #4d4cf7 100%);
    /* padding-left: 1vw;
    padding-right: 1vw; */
  }
  .sec2-badge h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 23.5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sec2-badge h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    width: 20vw;
  }
  /* #second-slide {
    
  } */
  .section {
    display: flex;
    justify-content: center;
    margin-top: 18vh;
  }
}
</style>
