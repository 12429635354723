<template>
  <div id="section7">
    <img :src="imageUrl" alt="Managed" id="sec7-image-left" />
    <div id="sec7-content">
      <h2>Get In Touch</h2>
      <h3>
        Unlock the potential of your supply chain with The Manage. Reach out to
        get more product insights, demo, or a personalized quote. Your journey
        to enhanced supply chain excellence starts here!
      </h3>
      <div id="sec7-input">
        <div id="input-box">
          <img :src="imageUrl2" alt="Email" />
          <input type="text" placeholder="Enter your E-mail" v-model="email" />
        </div>
        <button id="sec7-button" @click="toggleCalendly('section1')">
          Connect
        </button>
        <div
          class="calendly-inline-widget"
          data-url="
https://calendly.com/themanage/get-in-touch?hide_event_type_details=1&hide_gdpr_banner=1&background_color=04042d&text_color=f8f9fb&primary_color=2613b7"
          v-show="flag"
          style="
            min-width: 60vw;
            height: 700px;
            position: absolute;
            top: 12%;
            left: 20%;
            z-index: 100;
            /* border: 2px solid red; */
            /* background-color: #04042e; */
            /* overflow: hidden; */
          "
        ></div>
        <div
          v-show="flag"
          style="
            position: absolute;
            top: 15%;
            right: 25%;
            /* border: 2px solid white; */
            display: flex;
            justify-content: center;
            cursor: pointer;
            z-index: 100;
          "
          @click="closeCalendly"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="15"
            viewBox="0 0 19 15"
            fill="none"
          >
            <path
              d="M3.521 13.8677L14.521 1.86768"
              stroke="white"
              stroke-width="1.9"
              stroke-linecap="round"
            />
            <path
              d="M3.4585 1.84424L15.4585 13.8442"
              stroke="white"
              stroke-width="1.9"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <img :src="imageUrl" alt="Managed" id="sec7-image-right" />
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  data() {
    return {
      imageUrl:
        "https://d266jsegxhahfs.cloudfront.net/Manage/general/cube-transparent.png",
      imageUrl2:
        "https://d266jsegxhahfs.cloudfront.net/Manage/landing/icons/mail.png",
    };
  },

  setup() {
    const email = ref("");

    let flag = ref(false);
    function toggleCalendly(sectionId) {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ top: 0, behavior: "smooth" });
      flag.value = true; // Toggle the flag when the button is clicked
    }
    function closeCalendly() {
      flag.value = false; // Toggle the flag when the button is clicked
    }

    return {
      email,
      toggleCalendly,
      closeCalendly,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 480px) {
  #section7 {
    width: 100vw;
    margin-top: 1vh;
  }
  #sec7-image-left {
    display: none;
  }
  #sec7-image-right {
    display: none;
  }
  #sec7-content {
    width: 100%;
    /* padding-left: 3vw;
    padding-right: 3vw; */
  }
  #sec7-content h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125.269px; /* 835.128% */
    letter-spacing: 1.825px;
    margin-bottom: 0;
  }
  #sec7-content h3 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 192.857% */
    margin-top: -6vh;
    padding: 0 20px;
  }
  #sec7-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 6vh;
    /* padding-left: 20px; */
  }
  #input-box {
    border-radius: 61px;
    background: #f8f9fb;
    width: 54vw;
    height: 41px;
    padding-left: 1.5vw;
    padding-right: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #input-box img {
    width: 23px;
    height: 23px;
    margin-right: 1vw;
  }
  #input-box input,
  #input-box input:focus,
  #input-box input::selection {
    /* height: 41px;
    width: 50vw; */
    border: none;
    outline: none;
    border-radius: 61px;
    background: transparent;
    color: #393d3d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 41.71px; 231.722% */
  }
  #input-box input::placeholder {
    color: #6e7474;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.71px; /* 231.722% */
  }
  #sec7-button {
    /* width: 0vw; */
    /* height: 41px; */
    border-radius: 38.5px;
    background: #2613b7;
    outline: none;
    border: none;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: #fff;
    /* text-align: center; */
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 13.53px; 150% */
    /* margin-left: 2.5vw; */
    padding: 0 20px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 960px) {
  #section7 {
    width: 100vw;
    display: grid;
    grid-template-columns: 10vw 80vw 10vw;
    overflow: hidden;
    margin-top: 2vh;
  }
  #sec7-image-left {
    width: 80vw;
    height: 40vh;
    margin-top: 15vh;
    transform: rotate(-168.355deg);
    opacity: 0.5;
    margin-left: -40vw;
  }
  #sec7-image-right {
    width: 80vw;
    height: 40vh;
    margin-top: -8vh;
    transform: rotate(11.645deg);
    margin-left: -25vw;
    opacity: 0.4;
  }
  #sec7-content {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -10vh;
  }
  #sec7-content h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 35.483px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.269px; /* 258.375% */
    letter-spacing: 1.825px;
    margin-bottom: 0pc;
  }
  #sec7-content h3 {
    color: white;
    font-family: Inter;
    font-size: 21.815px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.722px;
    margin-top: 0;
    text-align: center;
    line-height: 33px; /* 150% */
    width: 80vw;
    margin-top: -1vh;
    margin-bottom: 5.5vh;
  }
  #sec7-input {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -1vh;
    z-index: 1;
  }
  #input-box {
    border-radius: 61px;
    background: #f8f9fb;
    width: 55vw;
    height: 61px;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #input-box img {
    width: 40px;
    height: 40px;
    margin-right: 1.25vw;
  }
  #input-box input,
  #input-box input:focus,
  #input-box input::selection {
    height: 61px;
    width: 44vw;
    border: none;
    outline: none;
    border-radius: 61px;
    background: transparent;
    color: #393d3d;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.71px; /* 231.722% */
  }
  #input-box input::placeholder {
    color: #6e7474;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.71px; /* 231.722% */
  }
  #sec7-button {
    width: 35vw;
    height: 61px;
    border-radius: 38.5px;
    background: #2613b7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Quicksand;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.5px; /* 150% */
    margin-left: 2.5vw;
    border: none;
  }
}
@media only screen and (min-width: 961px) {
  #section7 {
    width: 100vw;
    display: grid;
    grid-template-columns: 20vw 60vw 20vw;
    overflow: hidden;
    margin-top: 8vh;
  }
  #sec7-image-left {
    width: 50vw;
    height: 50vh;
    margin-top: 10vh;
    margin-left: -25vw;
    transform: rotate(-168.355deg);
    opacity: 0.5;
  }
  #sec7-image-right {
    width: 50vw;
    height: 50vh;
    margin-top: -10vh;
    transform: rotate(11.645deg);
    margin-left: -5vw;
    opacity: 0.4;
  }
  #sec7-content {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #sec7-content h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 125.269px; /* 258.375% */
    letter-spacing: 1.825px;
    margin-bottom: -10px;
  }
  #sec7-content h3 {
    color: white;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.722px;
    margin-top: 0;
    text-align: center;
    line-height: 30px; /* 150% */
    width: 70vw;
    /* border: 2px solid red; */
    margin-top: 0;
    margin-bottom: 5.5vh;
  }
  #sec7-input {
    width: 60vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
  }
  #input-box {
    border-radius: 61px;
    background: #f8f9fb;
    width: 28vw;
    height: 53px;
    padding-left: 1vw;
    padding-right: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #input-box img {
    width: 36px;
    height: 36px;
    margin-right: 1vw;
  }
  #input-box input,
  #input-box input:focus,
  #input-box input::selection {
    height: 51px;
    width: 24vw;
    border: none;
    outline: none;
    border-radius: 61px;
    background: transparent;
    color: #393d3d;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.71px; /* 231.722% */
  }
  #input-box input::placeholder {
    color: #6e7474;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.71px; /* 231.722% */
  }
  #sec7-button {
    /* width: 12vw; */
    height: 53px;
    padding: 0 3rem;
    border-radius: 38.5px;
    background: #2613b7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: Quicksand;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.5px; /* 150% */
    margin-left: 2.5vw;
    border: none;
  }
}
</style>
